<template>
    <svg
        class="w-full h-full"
        width="24"
        height="20"
        viewBox="0 0 24 20"
        stroke="currentColor"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 14L18 19L23 14"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18 7V19"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1 9H10"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1 1H18"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1 17H8"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
